<template>
  <Box ref="formRef" flex col space="6" component="form" @submit="onSubmit($event)">
    <FormField
      :id="getId('name')"
      v-slot="{ id, disabled }"
      :disabled="formDisabled"
      required
      label="Site Event Name"
    >
      <InputText
        :id="id"
        v-model="name"
        :disabled="disabled"
      />
    </FormField>

    <Box flex col space="2">
      <Transitions group>
        <template v-for="(condition, index) in conditions.fields" :key="condition.key">
          <AudienceCondition :index="index" />
          <Box v-if="!condition.isLast" flex justify="center">
            <AudienceConditionOperator />
          </Box>
        </template>
      </Transitions>

      <Box>
        <AddLink :disabled="formDisabled || maxConditionsReached" noIcon @click="onAddCondition()">
          Add Condition
        </AddLink>
      </Box>
    </Box>

    <Box flex spaceX="2" justify="between" alignItems="end" grow="grow" wrap="wrap">
      <Button :disabled="submitting" variant="outlined" size="lg" @click="emit('close')">
        Cancel
      </Button>
      <Button type="submit" size="lg" color="primary" :loading="submitting" :disabled="formDisabled">
        Save
      </Button>
    </Box>
  </Box>
</template>

<script setup lang="ts">
import { UniversalPixelAudienceInfo } from '@lasso/api-activation/activation'
import { AddLink, Box, Button, FormField, InputText, Transitions } from '@lasso/luikit'
import { useNavigationGuard } from '@lasso/shared/hooks'

import AudienceCondition from './AudienceCondition.vue'
import AudienceConditionOperator from './AudienceConditionOperator.vue'
import { useProvideAudienceForm } from './useAudienceForm'
import { useAudienceFormSubmit } from './useAudienceFormSubmit'

const { audience, pixelId } = defineProps<{
  pixelId: number
  audience?: UniversalPixelAudienceInfo
}>()

const emit = defineEmits<{
  close: []
}>()

const {
  name,
  getId,
  formRef,
  submitting,
  formDisabled,
  dirty,
  conditions,
  maxConditionsReached,
} = useProvideAudienceForm(audience)

useNavigationGuard(dirty)

const onAddCondition = () => {
  conditions.push({
    type: undefined,
    comparison: undefined,
    keywords: [],
  })
}

const { onSubmit } = useAudienceFormSubmit({
  pixelId: () => pixelId,
  audience: () => audience,
})
</script>
