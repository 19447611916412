<template>
  <ErrorState v-if="error" :error="error" @retry="retry()" />
  <LayoutLanding v-else>
    <template #title>
      <Skeleton v-if="fetching" width="300px" height="30px" />
      <template v-else>
        {{ pixelName }}
      </template>
    </template>

    <template #actions>
      <template v-if="fetching">
        <Skeleton width="128px" height="36px" />
        <Skeleton width="116px" height="36px" />
      </template>
      <template v-else>
        <Button size="lg" variant="outlined" @click="togglePixelScriptModal(true)">
          View Pixel Code
        </Button>
        <Button v-if="permissions.digitalEvents.canManage" size="lg" color="primary" @click="onAddAudienceClick()">
          Add Site Event
        </Button>
      </template>
    </template>

    <template #breadcrumbs>
      <Breadcrumbs :crumbs="breadcrumbs" />
    </template>

    <UniversalPixelAudiences :pixelId="pixelId" />

    <UniversalPixelCodeModal v-model="pixelScriptModalOpen" :scriptText="pixelScriptText" />
  </LayoutLanding>
</template>

<script setup lang="ts">
import { Breadcrumbs, BreadcrumbsCrumb, Button, ErrorState, LayoutLanding, Skeleton } from '@lasso/luikit'
import { useRoute, useRouter } from 'vue-router'
import { RouteName } from '@activation-objects/router'
import { useApi } from '@lasso/shared/hooks'
import { useActivationApi } from '@lasso/api-activation/activation'
import { computed } from 'vue'
import { onError404 } from '@lasso/api-shared'
import { useToggle } from '@vueuse/core'
import { useAccessControl } from '@activation-objects/hooks/useAccessControl'

import { UniversalPixelCodeModal } from '../UniversalPixelCodeModal'

import { UniversalPixelAudiences } from './UniversalPixelAudiences'

const { pixelId } = defineProps<{
  pixelId: number
}>()

const { permissions } = useAccessControl()

const router = useRouter()
const route = useRoute()

const breadcrumbs: BreadcrumbsCrumb[] = [
  { label: 'Universal Pixels', path: router.resolve({ name: RouteName.UniversalPixels }).path },
  { label: 'Pixel Details', path: router.resolve({ name: RouteName.UniversalPixelDetails }).path },
]

const onAddAudienceClick = () => {
  router.push({
    name: RouteName.UniversalPixelAudienceCreate,
    params: { pixelId },
    query: route.query,
  })
}

const { data, fetching, error, retry, onError } = useApi(
  useActivationApi().getUniversalPixel,
  () => [pixelId],
  { refetch: true, cache: true, refetchOnCacheClear: true },
)

onError(onError404)

const pixelName = computed(() => data.value?.data.name ?? '')
const pixelScriptText = computed(() => data.value?.data.scriptText ?? '')

const [pixelScriptModalOpen, togglePixelScriptModal] = useToggle()
</script>
