<template>
  <ModalBase
    variant="drawer"
    modelValue
    title="Add Universal Pixel"
    noSelfClose
    size="lg"
    @close="onClose()"
  >
    <Box ref="formRef" component="form" flex col spaceY="6" @submit="onSubmit($event)">
      <FormField :id="getId('marketerId')" v-slot="{ id, disabled }" :disabled="submitting" label="Marketer" required>
        <ErrorState v-if="accountsApi.error" :error="accountsApi.error" inline @retry="accountsApi.retry()" />
        <Autocomplete2
          v-else
          :id="id"
          v-model="marketerIdInternal"
          :options="marketersOptions"
          :disabled="disabled"
          :loading="accountsApi.loading"
          placeholder="Search"
          icon="search"
        />
      </FormField>

      <FormField
        :id="getId('advertiserId')"
        v-slot="{ id, disabled }"
        :disabled="marketerId === null || submitting"
        label="Advertiser"
        required
      >
        <ErrorState v-if="accountsApi.error" :error="accountsApi.error" inline @retry="accountsApi.retry()" />
        <Autocomplete2
          v-else
          :id="id"
          v-model="advertiserIdInternal"
          :options="advertisersOptions"
          :disabled="disabled"
          :loading="accountsApi.loading"
          placeholder="Search"
          icon="search"
        />
      </FormField>

      <FormField label="Pixel Name">
        <InputText :modelValue="pixelName" disabled />
      </FormField>

      <Box flex spaceX="2" justify="between" alignItems="end" grow="grow" wrap="wrap">
        <Button :disabled="submitting" variant="outlined" size="lg" @click="onClose()">
          Cancel
        </Button>
        <Button type="submit" size="lg" color="primary" :loading="submitting">
          Save
        </Button>
      </Box>
    </Box>
  </ModalBase>
</template>

<script setup lang="ts">
import {
  Autocomplete2,
  Box,
  Button,
  ErrorState,
  FormField,
  InputText,
  ModalBase,
  buildSelectOptions,
} from '@lasso/luikit'
import { useRoute, useRouter } from 'vue-router'
import { RouteName } from '@activation-objects/router'

import { useApiManual, useBetterForm, useNavigationGuard, useToast } from '@lasso/shared/hooks'
import { computed, reactive, ref, watch } from 'vue'
import { useAccounts } from '@activation-objects/hooks/useAccounts'
import { useActivationApi } from '@lasso/api-activation/activation'
import { number, object, string } from 'yup'
import { ChannelENUM } from '@lasso/api-activation/activation/legacy'
import { whenever } from '@vueuse/core'

const router = useRouter()
const route = useRoute()
const toast = useToast()

const onClose = () => {
  router.push({ name: RouteName.UniversalPixels, query: route.query })
}

const api = useActivationApi()

const accountsApi = reactive(useAccounts())
const { requestThrows: createUniversalPixel } = useApiManual(api.createUniversalPixel, {
  clearCacheFor: [api.getUniversalPixels, api.getUniversalPixel],
})

const { useFieldModels, getId, submitting, handleSubmit, formRef, dirty, resetForm, setInitialValue } = useBetterForm({
  validationSchema: object({
    marketerId: number().required().nullable().default(null),
    advertiserId: number().required().nullable().default(null),
    exchangeSeatId: string().nullable().default(null),
  }),
  initialValues: ref({
    marketerId: null,
    advertiserId: null,
    exchangeSeatId: null,
  }),
})

useNavigationGuard(dirty)

const {
  marketerId,
  advertiserId,
  exchangeSeatId,
} = useFieldModels(['marketerId', 'advertiserId', 'exchangeSeatId'])

const marketerIdInternal = computed({
  set: (value) => {
    marketerId.value = value || null
  },
  get: () => marketerId.value ?? '',
})

const advertiserIdInternal = computed({
  set: (value) => {
    advertiserId.value = value || null
  },
  get: () => advertiserId.value ?? '',
})

const marketersOptions = computed(() => buildSelectOptions(accountsApi.marketers, 'id', 'name'))
const advertisersOptions = computed(() => {
  return buildSelectOptions(
    accountsApi.advertisers.filter(advertiser => advertiser.marketerId === marketerId.value),
    'id', 'name',
  )
})

const selectedMarketer = computed(() => {
  return accountsApi.marketers.find(marketer => marketer.id === marketerId.value)
})

const selectedAdvertiser = computed(() => {
  return accountsApi.advertisers.find(advertiser => advertiser.id === advertiserId.value)
})

watch(selectedMarketer, (marketer) => {
  // Auto-select marketer-specific seat id if available
  exchangeSeatId.value = marketer ? accountsApi.getMarketerExchangeId(marketer.id, ChannelENUM.Banner) : null

  if (selectedAdvertiser.value && selectedAdvertiser.value.marketerId !== marketer?.id) {
    advertiserId.value = null
  }
}, { flush: 'sync' })

whenever(() => accountsApi.loaded, () => {
  if (!selectedMarketer.value && marketersOptions.value.length === 1) {
    setInitialValue('marketerId', marketersOptions.value[0]!.value)
  }

  if (selectedMarketer.value && !selectedAdvertiser.value && advertisersOptions.value.length === 1) {
    setInitialValue('advertiserId', advertisersOptions.value[0]!.value)
  }
})

const pixelName = computed(() => {
  if (!selectedAdvertiser.value) {
    return ''
  }

  return `UP ${selectedAdvertiser.value.id} ${selectedAdvertiser.value.name}`
})

const onSubmit = handleSubmit(async (values) => {
  let result

  try {
    result = await createUniversalPixel({
      marketerId: values.marketerId,
      advertiserId: values.advertiserId,
      exchangeSeatId: values.exchangeSeatId,
    })
  }
  catch (error) {
    toast.error('Failed to create universal pixel', error)
    return
  }

  if (!result) {
    return
  }

  resetForm({ values })

  const pixelId = result.universalPixelId

  toast.success('Universal pixel was successfully created', '', {
    actions: [{
      title: 'Show pixel',
      onClick: () => {
        router.push({ name: RouteName.UniversalPixelDetails, params: { pixelId } })
      },
    }],
  })

  onClose()
})
</script>
