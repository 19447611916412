<template>
  <LayoutLanding>
    <template #title>
      Digital Events
    </template>

    <template #actions>
      <Button v-if="permissions.digitalEvents.canManage" size="lg" color="primary" @click="onAddPixelClick()">
        Add Universal Pixel
      </Button>
    </template>

    <template #tabs>
      <Tabs :active="activeTabName" size="lg" @update:active="onTabClick($event)">
        <TabsItem v-for="tab in tabs" :key="tab.value" :value="tab.value">
          {{ tab.label }}
        </TabsItem>
      </Tabs>
    </template>

    <RouterView v-slot="subRoute">
      <Transitions mode="out-in">
        <Box>
          <component :is="subRoute.Component ?? Box" />
        </Box>
      </Transitions>
    </RouterView>
  </LayoutLanding>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { Box, Button, LayoutLanding, SelectOptionType, Tabs, TabsItem, Transitions } from '@lasso/luikit'
import { useRoute, useRouter } from 'vue-router'
import { RouteName } from '@activation-objects/router'
import { navigateToAppUrl, truthy } from '@lasso/shared/utils'
import { useAccessControl } from '@activation-objects/hooks/useAccessControl'

const { permissions, features } = useAccessControl()

const router = useRouter()
const route = useRoute()

const tabs = computed((): Array<SelectOptionType<string> & { url: string }> => ([
  {
    value: RouteName.UniversalPixels,
    label: 'Universal Pixels',
    url: router.resolve({ name: RouteName.UniversalPixels }).path,
  },
  {
    value: 'LassoPixel',
    label: 'Pixels',
    url: 'activation/web-events/pixel',
  },
  features.retargeting && {
    value: 'Retargeting',
    label: 'Retargeting',
    url: 'activation/web-events/retargeting-audience',
  },
].filter(truthy)))

const activeTabName = ref<string>(RouteName.UniversalPixels)

const onTabClick = (value: string) => {
  const tab = tabs.value.find(tab => tab.value === value)

  if (tab) {
    navigateToAppUrl(tab.url)
  }
}

const onAddPixelClick = () => {
  router.push({ name: RouteName.UniversalPixelCreate, query: route.query })
}
</script>
